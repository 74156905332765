import { Tooltip } from '@mui/material';
import React, { ReactElement } from 'react';

import { Product } from '../../../hooks/use-products';
import { classNames } from '../../../utils/classes';
import NodeTreeBadge from '../../badges/tree-node-badge';

type Props = Product & {
    selected: boolean;
    gridSize: number;
    onClick: (id: string) => void;
    displayType: 'full' | 'auto';
};

export default function ProductListItem(props: Props): ReactElement {
    const { id, displayType, selected, name, onClick, perspectives, animations, thumbnailUrl, gridSize } = props;

    const hasPerspectives = perspectives && perspectives.length > 0;
    const hasAnimations = animations && animations.length > 0;

    const showNodeTreeBadge =
        (perspectives && perspectives.length > 1) ||
        (animations && animations.length > 1) ||
        (hasAnimations && hasPerspectives);

    return (
        <div style={{ width: `${gridSize}px` }} key={id} className='inline-block cursor-pointer break-all'>
            <button type='button' className='flex max-w-full flex-col space-y-2' onClick={() => onClick(id)}>
                <div
                    className={classNames(
                        'relative overflow-hidden rounded object-contain',
                        selected ? 'ring-4 ring-company ring-offset-4' : '',
                    )}
                >
                    {thumbnailUrl && (
                        <img
                            src={thumbnailUrl}
                            alt={`${name} thumbnail`}
                            style={{
                                width: displayType ? '100%' : 'auto',
                                aspectRatio: '1/1',
                            }}
                            className='object-contain'
                        />
                    )}
                    {showNodeTreeBadge && <NodeTreeBadge size={gridSize / 5} />}
                </div>
                <Tooltip title={name} placement='bottom' arrow>
                    <p className='max-w-full truncate text-sm text-gray-500'>{name}</p>
                </Tooltip>
            </button>
        </div>
    );
}
